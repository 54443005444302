import React from 'react';
import './index.css';

function App() {
  return (
    <div className="app">
        <div className="welcome">
            <h1>Meg Eru Art</h1>
            <p>coming soon</p>
        </div>
    </div>
  );
}

export default App;